.IconGrid {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .IconGrid {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0;
  }
}
