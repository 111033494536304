.footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.footer-text {
  display: flex;
  font-family: "Open Sans", sans-serif;
  color: #6fc5bd;
  margin: 48px 0;
}

@media screen and (max-width: 600px) {
  .footer-text {
    margin: 24px 0;
  }
}
