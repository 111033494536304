.App {
  display: grid;
  height: 100vh;
}
.logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 992px) {
  .logo {
    margin-top: 120px;
  }
}
